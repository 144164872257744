import { type HTMLAttributes, type PropsWithChildren } from 'react'
import BackgroundLightPoint from '#app/components/background/bg-light-point'
import { Container } from '#app/components/layout/container.tsx'
import { Flex } from '#app/components/layout/flex'
import { cn } from '#app/utils/tailwind-merge.ts'

const CenteredFormContainer = ({ className, ...props }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
	return (
		<BackgroundLightPoint>
			<Container className="">
				<main>
					<Flex orientation="vertical" items="center" gap="14" className={cn('centered-form-container', className)} {...props} />
				</main>
			</Container>
		</BackgroundLightPoint>
	)
}
CenteredFormContainer.displayName = 'CenteredFormContainer'

export { CenteredFormContainer }
