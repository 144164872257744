import { type HTMLAttributes, type PropsWithChildren } from 'react'

const BackgroundLightPoint = ({ children, ...props }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
	return (
		<div className="relative" {...props}>
			<div className="absolute -top-[--header-height] left-0 -z-50 h-[calc(var(--header-height)+100%+var(--footer-height))] w-full bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-muted-200 from-0% via-muted-50 via-30% to-muted-50 to-70%" />
			{children}
		</div>
	)
}

export default BackgroundLightPoint
